<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <v-icon> mdi-eye </v-icon>
            <span class="ml-7 subtitle-1">Menu</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" :to="item.link" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div v-if="isAdmin">

        <v-divider></v-divider>
        <v-list dense nav>
          <v-list-item v-for="item in itemsAdmin" :key="item.title" :to="item.link" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item v-for="item in itemPublico" :key="item.title" @click="openInNewTab(item.link)" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="item.icon == 'mdi-map'">
            <v-icon small color="grey lighten-1">mdi-open-in-new</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <template v-slot:append v-if="isLoggedin">
        <div class="pa-2 mb-12">
          <v-btn @click="logoff" block color="primary"> Sair </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar color="secondary" app>
      <v-app-bar-nav-icon color="primary" @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title color="primary">De Olho na Guanabara</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>


<script>
import { isSignedIn, signOut } from "../auth";
import { isAdmin } from "@/auth"

export default {
  data() {
    return {
      drawer: null,
      items: [
        {
          title: "Mapa Campo",
          icon: "mdi-map-marker-plus",
          link: "/app/campo",
        },
      ],
      itemsAdmin: [
        {
          title: "Moderador",
          icon: "mdi-shield-crown ",
          link: "/app/moderador",
        },
        {
          title: "Registrar Usuario",
          icon: "mdi-account-plus",
          link: "/app/registrar",
        },
      ],
      itemPublico: [
        {
          title: "Meu perfil",
          icon: "mdi-account-edit",
          link: "/app/meu-perfil",
        },
        {
          title: "Mapa público",
          icon: "mdi-map",
          link: "/mapa",
        }
      ]
    };
  },
  methods: {
    logoff() {
      signOut();
      window.location.assign("/login");
      //  window.sessionStorage.setItem('token', `false`)
      //  window.location.assign('/')
    },
    openInNewTab(item) {
      if (item == "/app/meu-perfil") {
        this.$router.push(item)
      } else {

        window.open(this.$router.resolve('/').href, '_blank');
      }
    }
  },
  computed: {
    isAdmin() {
      return isAdmin() ? true : false
    },
    isLoggedin() {
      if (isSignedIn()) {
        // de acessar a página Home.
        return true;
      } else {
        return false;
      }
      //  const existsSessionAuth = window.sessionStorage.getItem('auth')
      //  return existsSessionAuth == 'false'|| !existsSessionAuth ? false : true
    },
  },

};
</script>